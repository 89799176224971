.card-inner-icon{
    border-style: dashed;
    border-color:  #ee7e19;
    border-width: 2px;
    border-radius: 50px;

    font-size: 70px;
    padding: 13px;
    color: #50af9b;
}

@media only screen and (max-width : 600px) {

    .contact-page-inner-2{
padding: 30px;
    }

    .video_content{
        height: 250px;
        width: 100%;
        background-color: black;
    }

    .autotype-text{
        position: relative;
        margin: 0px;
        top: 0px;
        transform: translateY(0px);
    }

    .vidoe-banner{
        position: relative;
    width: 100%;
    bottom: 0%;
    transform: translateY(-0%);
    }


    .con-card h3{
        color: #D75A5A;
        
        }

        .contact-inner-card{
            height: auto;
            }
        
        .con-card p{
        color: black;
        }

.breadcrumb-area {
    padding: 185px 0 50px;

}
    .footer-subscribe .footer-subscribe-inner.style-2 {
        display: none !important;
        }    
}

@media only screen and (min-width : 998px){
    .con-card h3{
color: #D75A5A;

}

.autotype-text{
    position: absolute;
    margin: 0px;
    top: 10px;
    transform: translateY(-30px);
}

.contact-page-inner-2{
padding: 30px 100px;
}

.video_content{
    height: 600px;
    width: 100%;
    background-color: black;
}

.con-card p{
color: white;
}

    .breadcrumb-area {
        /* padding: 222px 0 108px; */
        padding: 160px 0 50px;


    }

    .contact-inner-card{
        height: 100px;
        }

        .contact-inner-info p{
            font-size: 10px;
        }

.col-build-5{
    width: 20%;
}
}



@media only screen and (min-width : 1400px){

    .contact-page-inner-2{
        padding: 30px 100px;

    }

    .autotype-text{
        position: absolute;
        margin: 0px;
        top: 30px;
        transform: translateY(-30px);
    }

.contact-inner-card{
height: 105px;
}
    .breadcrumb-area {
        /* padding: 222px 0 108px; */
        padding: 160px 0 50px;

    }

    .contact-inner-info p{
        font-size: 12px;
    }

    .con-card h3{
        color: #D75A5A;
        
        }
        
        .con-card p{
        color: white;
        }

.col-build-5{
    width: 20%;
}
}


.title_Update{
    font-size: 17px;
}

.single-list-inner.style-heading li {
    font-size: 13px;
}
.FaqArea_title{
    font-size: 38px;
}

.com-logo{
    width:80%;
    height:150px;
}

.breadcrumb-area .page-title {
    font-size: 34px;
}

@media only screen and (min-width : 998px){

    .video_content{
        height: 600px;
        width: 100%;
        background-color: black;
    }

    .font-resp{
        font-size: 14px;
    }

    .vidoe-banner{
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .footer-subscribe .footer-subscribe-inner.style-2 {
    display: flex !important;
    }
    .title_Update{
        font-size: 17px;
    }
    .single-list-inner.style-heading li {
        font-size: 13px;
    }
}
@media only screen and (min-width : 1200px){

   

    .video_content{
        height: 600px;
        width: 70%;
        background-color: black;
    }

    .vidoe-banner{
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .title_Update{
        font-size: 17px;
    }
    .single-list-inner.style-heading li {
        font-size: 13px;
    }
}
@media only screen and (min-width : 1400px){

    .video_content{
        height: 600px;
        width: 70%;
        background-color: black;
    }

    .font-resp{
        font-size: 18px;
    }

    .vidoe-banner{
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .footer-subscribe .footer-subscribe-inner.style-2 {
        display: flex !important;
        }

    .title_Update{
        font-size: 20px;
    }
    .single-list-inner.style-heading li {
        font-size: 15px;
    }
    .breadcrumb-area .page-title {
        font-size: 38px;
    }
    
}


@media (max-width: 600px){

.navbar {
    background-color: black;
}
}

.video-bg-map{
position: relative;
    
}



.contact-inner-img img{
height: 80px;
width: 300px;
/* padding-right:15px ; */
border-radius: 10px;
}

.contact-page-inner-2{
border-radius: 20px;
}

.contact-inner-info{
    margin-left: 15px;
}

.contact-inner-card{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 1px 4px 13px 0px #d5d5d5;
    
}

.single-work-process-inner-5  .inner-card-icon{
    font-size: 40px;
    color: #246bfd;
    transition: all 0.2s ease-in-out;
    /* transform: rotateY(0deg); */
}

.single-work-process-inner-5:hover .inner-card-icon{
    cursor: pointer;
    color: black;
    transform: rotateY(180deg);
}

.single-work-process-inner-4  .inner-card-icon{
    font-size: 40px;
    color: #246bfd;
    transition: all 0.2s ease-in-out;
    /* transform: rotateY(0deg); */
}

.single-work-process-inner-4:hover .inner-card-icon{
    cursor: pointer;
    color: black;
    transform: rotateY(180deg);
}

.single-work-process-inner-7  .inner-card-icon{
    font-size: 40px;
    color: #246bfd;
    transition: all 0.2s ease-in-out;
    /* transform: rotateY(0deg); */
}

.single-work-process-inner-7:hover .inner-card-icon{
    cursor: pointer;
    color: black;
    transform: rotateY(180deg);
}
.single-work-process-inner-6  .inner-card-icon{
    font-size: 40px;
    color: #246bfd;
    transition: all 0.2s ease-in-out;
    /* transform: rotateY(0deg); */
}

.single-work-process-inner-6:hover .inner-card-icon{
    cursor: pointer;
    color: black;
    transform: rotateY(180deg);
}

.single-work-process-inner-8  .inner-card-icon{
    font-size: 40px;
    color: #246bfd;
    transition: all 0.2s ease-in-out;
    /* transform: rotateY(0deg); */
}

.single-work-process-inner-8:hover .inner-card-icon{
    cursor: pointer;
    color: black;
    transform: rotateY(180deg);
}

.single-work-process-inner-9  .inner-card-icon{
    font-size: 40px;
    color: #246bfd;
    transition: all 0.2s ease-in-out;
    /* transform: rotateY(0deg); */
}

.single-work-process-inner-9:hover .inner-card-icon{
    cursor: pointer;
    color: black;
    transform: rotateY(180deg);
}

.single-work-process-inner-10  .inner-card-icon{
    font-size: 40px;
    color: #246bfd;
    transition: all 0.2s ease-in-out;
    /* transform: rotateY(0deg); */
}

.single-work-process-inner-10:hover .inner-card-icon{
    cursor: pointer;
    color: black;
    transform: rotateY(180deg);
}

.thumber{
    position: relative;
    border-radius: 3px 50% 50% 50%;
    height: 80px;
    width: 80px;
    background-color: #f8f9fc;
    transition: all 0.3s ease-in-out;

}

.about-icons{
    position: absolute;
    font-size: 35px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.inner-top-card:hover .thumber{
 background-color: #246bfd;
}

.inner-top-card .thumber .about-icons{
    color: black;
}

.inner-top-card:hover .about-icons{
    color: white;

    cursor: pointer;
}

.footer-icon{
width: 100%;
height: 80px;
border-radius: 10px;
margin-top: 10px;
}

.footerLogo{
        width: 100%;
        height: auto;
        margin: 5px 0px ;
}

.auto-type{
    color: #ee7e19 !important;
    letter-spacing: 1px;
}

.typed-cursor {
    color: #da751b !important;
}

.font-resp{
    text-align: justify;
}



/* pre-loader */

.preloader{
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: #101a29;
    animation: opacit 2s infinite linear;
    transition: all 0.3s ease-in-out;
    display: flex;

}
/* 
   @keyframes opacit {
    0% {opacity: 1;}
     100% {opacity: 0;}
   } */

.preloader-icon{
    background-image: url('./img/logo/galileofavicon.png');
    height: 70px;
    width: 70px;
    background-repeat: no-repeat;
background-size: contain;

    animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
    0% {transform: rotate(180deg);}
     100% {transform: rotate(-180deg);}
   }

.preloader-letters{
    background-image: url('./img/logo/preloaderfavicon.png');
width: 150px;
background-repeat: no-repeat;
background-size: contain;
height: 30px;

}

.preloader-align{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
}


.bg-blue{
    background-image: url('./img/5.png');
}