.single-work-process-inner {
	border-radius: 5px;
	box-shadow: $box-shadow;
	margin-bottom: 30px;
	padding: 30px 20px;
    transition: 0.4s;
	.thumb {
		img {
			transition: 0.4s;
		}

	}
	.details {
		.process-count {
			color: var(--main-color);
			font-weight: 600;
		}
		h5 {
			font-weight: 700;
		}
		.content {
			margin-bottom: 0;
		}
	}
	&:hover {
		box-shadow: $box-shadow2;
		.thumb {
			img {
				transform: rotateY(180deg);
			}
		}
	}
}

.single-work-process-inner-4 {
	border-radius: 5px;
	// height: 380px;
	box-shadow: $box-shadow;
	margin-bottom: 30px;
	padding: 30px 20px;
    transition: 0.4s;
	.thumb {
		img {
			transition: 0.4s;
		}
	}
	.details {
		.process-count {
			color: #ee7f1b;
			font-weight: 600;
		}
		h5 {
			font-weight: 700;
		}
		.content {
			margin-bottom: 0;
		}
	}
	&:hover {
		box-shadow: $box-shadow2;
		.thumb {
			img {
				transform: rotateY(180deg);
			}
		}
	}
}

.single-work-process-inner-5 {
	border-radius: 5px;
	// height: 380px;
	box-shadow: $box-shadow;
	margin-bottom: 30px;
	padding: 30px 20px;
    transition: 0.4s;
	.thumb {
		img {
			transition: 0.4s;
		}
	}
	.details {
		.process-count {
			color: #ee7f1b;
			font-weight: 600;
		}
		h5 {
			font-weight: 700;
		}
		.content {
			margin-bottom: 0;
		}
	}
	&:hover {
		box-shadow: $box-shadow2;
		.thumb {
			img {
				transform: rotateY(180deg);
			}
		}
	}
}

.single-work-process-inner-6 {
	border-radius: 5px;
	// height: 380px;
	box-shadow: $box-shadow;
	margin-bottom: 30px;
	padding: 30px 20px;
    transition: 0.4s;
	.thumb {
		img {
			transition: 0.4s;
		}
	}
	.details {
		.process-count {
			color: #ee7f1b;
			font-weight: 600;
		}
		h5 {
			font-weight: 700;
		}
		.content {
			margin-bottom: 0;
		}
	}
	&:hover {
		box-shadow: $box-shadow2;
		.thumb {
			img {
				transform: rotateY(180deg);
			}
		}
	}
}

.single-work-process-inner-7 {
	border-radius: 5px;
	// height: 380px;
	box-shadow: $box-shadow;
	margin-bottom: 30px;
	padding: 30px 20px;
    transition: 0.4s;
	.thumb {
		img {
			transition: 0.4s;
		}
	}
	.details {
		.process-count {
			color: #ee7f1b;
			font-weight: 600;
		}
		h5 {
			font-weight: 700;
		}
		.content {
			margin-bottom: 0;
		}
	}
	&:hover {
		box-shadow: $box-shadow2;
		.thumb {
			img {
				transform: rotateY(180deg);
			}
		}
	}
}

.single-work-process-inner-8 {
	border-radius: 5px;
	// height: 380px;
	box-shadow: $box-shadow;
	margin-bottom: 30px;
	padding: 30px 20px;
    transition: 0.4s;
	.thumb {
		img {
			transition: 0.4s;
		}
	}
	.details {
		.process-count {
			color: #ee7f1b;
			font-weight: 600;
		}
		h5 {
			font-weight: 700;
		}
		.content {
			margin-bottom: 0;
		}
	}
	&:hover {
		box-shadow: $box-shadow2;
		.thumb {
			img {
				transform: rotateY(180deg);
			}
		}
	}
}

.single-work-process-inner-9 {
	border-radius: 5px;
	// height: 380px;
	box-shadow: $box-shadow;
	margin-bottom: 30px;
	padding: 30px 20px;
    transition: 0.4s;
	.thumb {
		img {
			transition: 0.4s;
		}
	}
	.details {
		.process-count {
			color: #ee7f1b;
			font-weight: 600;
		}
		h5 {
			font-weight: 700;
		}
		.content {
			margin-bottom: 0;
		}
	}
	&:hover {
		box-shadow: $box-shadow2;
		.thumb {
			img {
				transform: rotateY(180deg);
			}
		}
	}
}

.single-work-process-inner-10 {
	border-radius: 5px;
	// height: 380px;
	box-shadow: $box-shadow;
	margin-bottom: 30px;
	padding: 30px 20px;
    transition: 0.4s;
	.thumb {
		img {
			transition: 0.4s;
		}
	}
	.details {
		.process-count {
			color: #ee7f1b;
			font-weight: 600;
		}
		h5 {
			font-weight: 700;
		}
		.content {
			margin-bottom: 0;
		}
	}
	&:hover {
		box-shadow: $box-shadow2;
		.thumb {
			img {
				transform: rotateY(180deg);
			}
		}
	}
}

@media only screen and (max-width : 600px){
.single-work-process-inner-4 {
height: auto;
}

.single-work-process-inner-5 {
	height: auto;
	}

	.single-work-process-inner-6 {
		height: auto;
		}
	
		.single-work-process-inner-7 {
			height: auto;
			}

			.single-work-process-inner-8 {
				height: auto;
				}

				.single-work-process-inner-9 {
					height: auto;
					}

					.single-work-process-inner-10 {
						height: auto;
						}

.single-work-process-inner {
	height: auto;
	}

}

@media only screen and (min-width : 768px){
.single-work-process-inner-4{
height: auto;
}

.single-work-process-inner-5{
	height: auto;
	}

.single-work-process-inner{
	height: auto;
	}

	.single-work-process-inner-6 {
		height: auto;
		}

		.single-work-process-inner-7 {
			height: auto;
			}

			.single-work-process-inner-8 {
				height: auto;
				}

				.single-work-process-inner-9 {
					height: auto;
					}

					.single-work-process-inner-10 {
						height: auto;
						}

}

@media only screen and (min-width : 998px){
.single-work-process-inner-4 {
height: 350px;

}

.single-work-process-inner-5 {
	height: 220px;
	
	}

	.single-work-process-inner-6 {
		height: 180px;
		}

.single-work-process-inner{
	height: 365px;
	
	}

	.single-work-process-inner-7{
		height: 150px;
		
		}

		.single-work-process-inner-8{
			height: 160px;
			
			}

			.single-work-process-inner-9{
				height: 180px;
				
				}

				.single-work-process-inner-10{
					height: 280px;
					
					}
}

@media only screen and (min-width : 1400px){
.single-work-process-inner-4 {
height: 380px;
}

.single-work-process-inner-5 {
	height: 240px;
	}

.single-work-process-inner{
	height:400px;
	}

	.single-work-process-inner-6 {
		height: 160px;
		}

		.single-work-process-inner-7{
			height: 130px;
			
			}

			.single-work-process-inner-8{
				height: 160px;
				
			}
				.single-work-process-inner-9{
					height: 160px;
					}

					.single-work-process-inner-10{
						height: 260px;
						}

}




.single-work-process-inner-3 {
	padding: 40px;
	padding-top: 0;
	img {
		border-radius: 50%;
	}
}